import React, { createContext, useEffect, useState } from "react";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

export default function AuthProvider(props) {
  const { activate, account, deactivate } = useWeb3React();
  const [isLogin, setIsLogin] = useState(false);
  const [userData, setUserData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const disconnectWallte = async () => {
    // setUserData({});
    // setIsLogin(false);
    // setIsAdmin(false);
    deactivate();
  };

  let data = {
    userLoggedIn: isLogin,
    userData,
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: () => {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          activate(injected);
        }
      });
    },
    dicconectWalletFun: () => {
      disconnectWallte();
    },
  };

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
      console.log("hghjg", userAddress);
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
