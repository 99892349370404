/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { useLocation, matchPath, Link } from "react-router-dom";
import PolymerIcon from "@material-ui/icons/Polymer";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ReceiptIcon from "@material-ui/icons/Receipt";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import {
  Box,
  Drawer,
  Hidden,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  List,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";

// import DashboardIcon from '@material-ui/icons';
// import AccessAlarmIcon  from '@material-ui/icons/AccessAlarm';
import {
  FaSignOutAlt,
  FaUserCircle,
  FaSignInAlt,
  FaUser,
  FaQuestionCircle,
  FaCheckSquare,
  FaExchangeAlt,
  FaPoll,
  FaRegEdit,
} from "react-icons/fa";
// import {GrTransaction} from 'react-icons/gr';
import { AiFillSetting } from "react-icons/ai";
import { BsList } from "react-icons/bs";
import { RiDashboardFill } from "react-icons/ri";
import NavItem from "./NavItem";
const sections = [
  {
    subheader: "MENU",
    items: [
      {
        title: "Dashboard",
        icon: FaUserCircle,
        href: "/dashboard",
      },
      {
        title: "Commission",
        icon: PolymerIcon,
        href: "/commission",
      },
      {
        title: "Wallet Balance",
        icon: AccountBalanceWalletIcon,
        href: "/walletbalance",
      },
      // {
      //   title: "Transaction",
      //   icon: ReceiptIcon,
      //   href: "/transection",
      // },
    ],
  },
  // {

  //   title: 'NFT List',
  //   icon: FaUserCircle ,
  //   href: '/',

  // },]},
  {
    subheader: "USER",
    items: [
      {
        title: "User",
        icon: FaUser,
        href: "",
        items: [
          {
            title: "List User",
            icon: "",
            href: "/User-List",
          },
          // {
          //   title: "PlaceOrderList",
          //   icon: "",
          //   href: "/Report-User",
          // },
        ],
      },
    ],
  },

  {
    subheader: "NFT List",
    items: [
      {
        title: "NFT List",
        icon: BsList,
        href: "",

        items: [
          {
            title: "NFT List",
            icon: "",
            href: "/nftlist",
          },
          {
            title: "NFT Transaction ",
            icon: "",
            href: "/nft-mangement",
          },
          {
            // title: "Report user",
            // icon:'',
            // href: "/Report-User",

            title: "PlaceOrder List",
            icon: "",
            href: "/placeorderlistt",
          },
          {
            title: "Cancelled Order List",
            icon: "",
            href: "/cancelorderlist",
          },
          {
            title: "Reported Nft",
            icon: "",
            href: "/report-nft",
          },
        ],

      },
      {
        title: "Static Management",
        icon: EqualizerIcon,
        href: "/Static-content",
      },
    ],
  },
  // {
  //   subheader: "NFT Transaction List",
  //   items: [
  //     {
  //       title: "Transaction List",
  //       icon: BsList,
  //       href: "/nftlist",
  //       items: [
  //         {
  //           title: "Transaction",
  //           icon: ReceiptIcon,
  //           href: "/transection",
  //         },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   subheader: "CATEGORIES",
  //   items: [
  //     {
  //       title: "Categories",
  //       icon: RiDashboardFill,
  //       href: "/categories",
  //     },
  //   ],
  // },
  // {subheader:'FEES',
  // items: [
  // {
  //   title: 'Fees',
  //   icon: MdAttachMoney,
  //   href: '/Fee-List',
  // },]},
  // {subheader:'TRANSACTION',
  // items: [
  // {
  //   title: 'Transaction',
  //   icon: FaExchangeAlt,
  //   href: '/transaction',
  // },]},
  //   {subheader:'SETTINGS',
  //   items: [
  //   {
  //     title: 'Dashboard Settings',
  //     icon: AiFillSetting,
  //     href: '',
  //     items: [
  //       {
  //         title: "Featured User",
  //         icon: '',
  //         href: "/Feature-User",
  //       },

  //       {
  //         title: "Featured Collection",
  //         icon:'',
  //         href: "/Feature-Collection",
  //       },

  //     ],
  //   },

  //  ]},
  // {
  //   subheader: "Static Management",
  //   items: [
  //     {
  //       title: "Static Management",
  //       icon: FaRegEdit,
  //       href: "",
  //       items: [
  //         {
  //           title: "Terms of Use",
  //           icon: "",
  //           href: "/Termsof-Use",
  //         },
  //         {
  //           title: "Social Links",
  //           icon: "",
  //           href: "/Social-Links",
  //         },
  //         {
  //           title: "Reach us",
  //           icon: "",
  //           href: "/reachus",
  //         },
  //         // {
  //         //   title:"Left Side Data",
  //         //   icon:'',
  //         //   href:'/left-side-data',
  //         // },
  //       ],
  //     },
  //   ],
  // },
  {
    subheader: "LOGOUT",
    items: [
      // {
      //   title: "Logout",
      //   icon: FaSignOutAlt,
      //   href: "/",
      // },
    ],
  },
];

function renderNavItems({ items, ...props }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...props }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0,
  isNested = false,
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          isNested: true,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={
          <div style={{ width: isNested ? "auto" : "100%", textAlign: "left" }}>
            {item.title}
          </div>
        }
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    // background: theme.palette.primary.main,
    backgroundColor: "#2f5995cf",
  },
  desktopDrawer: {
    width: 256,
    top: 80,
    height: "calc(100% - 80px)",
    // background: theme.palette.primary.main,
    backgroundColor: "#04213a",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  lastbtn: {
    display: "flex-start",
    color: "#969ba1",
    "&:hover": {
      color: "#fff",
      // backgroundColor: "red",
    },
  },
  butm: {
    display: "flex",
    justifyContent: "center",
    // "&:hover": {
    //   backgroundColor: "red",
    // },
    paddingBottom: "30px",
  },
  butm1: {
    backgroundColor: "#252d47",
    color: "#fff",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  butm2: {
    backgroundColor: "#252d47",
    color: "#fff",
    "&:hover": {
      backgroundColor: "red",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box py={4}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader
                  disableGutters
                  disableSticky
                  style={{
                    paddingLeft: "15px",
                    fontSize: "13px",
                    fontWeight: "300",
                  }}
                >
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
          <Box
            style={{
              paddingLeft: "19px",
              fontSize: "13px",
              fontWeight: "300",
              alignItems: "center",
            }}
          >
            <Button
              // fullWidth
              className={classes.lastbtn}
              startIcon={<FaSignOutAlt />}
              onClick={() => {
                setOpen(true);
              }}
            // style={{ color: "#969ba1" }}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: "#52565c", fontSize: "20px" }}
          >
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.butm}>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.butm1}
            to="/"
            component={Link}
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.butm2}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
