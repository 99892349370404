import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/index")),
  },
  {
    exact: true,
    path: "/Change-Password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/Passwordchange")),
  },
  // {
  //   exact: true,
  //   path: '/signUp',
  //   layout: LoginLayout,
  //   component: lazy(() => import('src/views/auth/signUp')),
  // },
  // {
  //   exact: true,
  //   path: "/",
  //   // guard:true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard")),
  // },
  // {
  //   exact: true,
  //   path: "/",
  //   // guard:true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/component/DashboardCard")),
  // },
  {
    exact: true,
    path: "/dashboard",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/DashboardCard")),
  },
  {
    exact: true,
    path: "/commission",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Commission")),
  },
  {
    exact: true,
    path: "/walletbalance",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/WalletBalance")),
  },
  {
    exact: true,
    path: "/transection",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/Transections")),
  },
  {
    exact: true,
    path: "/list",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/List")),
  },
  {
    exact: true,
    path: "/placeorderlistdetailstwo",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Placeorderlistdetailstwo")
    ),
  },
  {
    exact: true,
    path: "/cancelorderlist",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/CancelorderList")),
  },
  {
    exact: true,
    path: "/report-nft",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ReportNft")),
  },
  {
    exact: true,
    path: "/forgot",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/Forgot")),
  },
  {
    exact: true,
    path: "/otp-verify",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/OtpVerify")),
  },

  {
    exact: true,
    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/Signup")),
  },
  {
    exact: true,
    path: "/User-List",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManagement/UserList")),
  },
  {
    exact: true,
    path: "/placeorderlistt",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManagement/PlaceOrder")),
  },

  {
    exact: true,
    path: "/placeorderlistdetails",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/PlaceOrderlistdetails")
    ),
  },
  {
    exact: true,
    path: "/view-nft",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ViewNft")),
  },

  {
    exact: true,
    path: "/Show-Report",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManagement/ShowReport")),
  },
  {
    exact: true,
    path: "/Edit-Report",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManagement/EditReport")),
  },

  {
    exact: true,
    path: "/categories",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Categories/index")),
  },
  {
    exact: true,
    path: "/View-Category",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Categories/Viewcategory")),
  },
  {
    exact: true,
    path: "/Add-Category",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Categories/Addcategory")),
  },
  {
    exact: true,
    path: "/Edit-Category",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Categories/Editcategory")),
  },

  {
    exact: true,
    path: "/Show-List",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManagement/Show")),
  },
  {
    exact: true,
    path: "/Edit-List",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManagement/Edit")),
  },
  {
    exact: true,
    path: "/Fee-List",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Fees/FeeList")),
  },
  {
    exact: true,
    path: "/New-Fee",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Fees/NewFee")),
  },
  {
    exact: true,
    path: "/Show-Fee",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Fees/ShowFee")),
  },
  {
    exact: true,
    path: "/Edit-Fee",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Fees/EditFee")),
  },

  {
    exact: true,
    path: "/transaction",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Transaction/TransactionList")
    ),
  },

  {
    exact: true,
    path: "/Feature-User",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboardsetting/Featureduser")
    ),
  },
  {
    exact: true,
    path: "/Add-Feature",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboardsetting/AddFeature")
    ),
  },
  {
    exact: true,
    path: "/Show-Feature",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboardsetting/ShowFeature")
    ),
  },
  {
    exact: true,
    path: "/Edit-Feature",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboardsetting/EditFeature")
    ),
  },

  {
    exact: true,
    path: "/listuser",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManagement/Listuser")),
  },

  {
    exact: true,
    path: "/Feature-Collection",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboardsetting/Featuredcollection")
    ),
  },
  {
    exact: true,
    path: "/Add-Collection",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboardsetting/AddCollection")
    ),
  },
  {
    exact: true,
    path: "/Show-Collection",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboardsetting/ShowCollection")
    ),
  },
  {
    exact: true,
    path: "/Edit-Collection",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboardsetting/EditCollection")
    ),
  },
  {
    exact: true,
    path: "/Termsof-Use",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Staticmanagement/index")),
  },
  {
    exact: true,
    path: "/Static-content",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContent/Index")),
  },
  {
    exact: true,
    path: "/View-content",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContent/ViewTerm")),
  },
  {
    exact: true,
    path: "/edit",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/Edit")
    ),
  },
  {
    exact: true,
    path: "/Social-Links",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Staticmanagement/Sociallinks")
    ),
  },
  ,
  {
    exact: true,
    path: "/Edit-Link",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Staticmanagement/Editsociallinks")
    ),
  },
  {
    exact: true,
    path: "/Show-Link",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Staticmanagement/Showsociallinks")
    ),
  },
  {
    exact: true,
    path: "/Show-Content",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Staticmanagement/Showcontent")
    ),
  },
  {
    exact: true,
    path: "/Edit-Content",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Staticmanagement/Editcontent")
    ),
  },
  {
    exact: true,
    path: "/Add-reachus",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Staticmanagement/AddReachus")
    ),
  },
  {
    exact: true,
    path: "/Add-Content",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Staticmanagement/AddStaticContent")
    ),
  },
  {
    exact: true,
    path: "/Add-Links",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Staticmanagement/AddSocialLinks")
    ),
  },

  {
    exact: true,
    path: "/reachus",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Staticmanagement/Reachus")),
  },
  {
    exact: true,
    path: "/view-reach-us",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Staticmanagement/Showreachus")
    ),
  },
  {
    exact: true,
    path: "/edit-reach-us",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Staticmanagement/Editreachus")
    ),
  },

  {
    exact: true,
    path: "/left-side-data",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Staticmanagement/LeftSideData/Leftsidedata")
    ),
  },

  {
    exact: true,
    path: "/edit-left-side-data",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Staticmanagement/LeftSideData/EditLeftSideData")
    ),
  },
  {
    exact: true,
    path: "/view-left-side-data",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Staticmanagement/LeftSideData/ShowLeftSideData")
    ),
  },

  {
    exact: true,
    path: "/view-logo",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Staticmanagement/LeftSideData/ShowLogo")
    ),
  },
  {
    exact: true,
    path: "/edit-logo",
    // guard:true,cd
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Staticmanagement/LeftSideData/EditLogo")
    ),
  },
  {
    exact: true,
    path: "/adminprofile",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboardsetting/Adminprofile")
    ),
  },
  {
    exact: true,
    path: "/notifications",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/Notification")),
  },
  {
    exact: true,
    path: "/nftlist",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Nftlist")),
  },
  {
    exact: true,
    path: "/nft-mangement",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/NftTransaction")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
