import React, { useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import Apiconfig from "../config/APIConfig";
import axios from "axios";

const Logo = () => {
  // image.src={props};
  const [linkdata, setlink] = React.useState();
  // const getlink = async () => {
  //   await axios.get(Apiconfig.sidedatalist).then(async (res) => {
  //     if (res.data.response_code === 200) {
  //       setlink(res.data.result);
  //     }
  //   });
  // };

  // useEffect(() => {
  //   getlink();
  // }, []);

  return (
    <>{linkdata && <img src={linkdata.logo.data} alt="err" srcalt="Logo" />}</>
  );
};

export default Logo;
