// const url = "https://node-smartcontract.mobiloitte.org";
// Base URL: node-agency-dirk.mobiloitte.com/

///api/v1/admin/viewUser
const url = "https://node-agency-dirk.mobiloitte.com";
// const url = "http://172.16.1.132:1849";

const Apiconfig = {
  login: `${url}/api/v1/admin/login`,
  profile: `${url}/api/v1/admin/getProfile`,
  editprofile: `${url}/api/v1/admin/editProfile`,
  userlist: `${url}/api/v1/admin/listUser`,
  viewuser: `${url}/api/v1/admin/viewUser`,
  staticdata: `${url}/api/v1/static/staticList`,
  staticedit: `${url}/api/v1/static/editStaticPage`,

  //sidemanagment
  // sidedatalist: `${url}/api/v1/sideData/sideDataList`,
  viewsidedata: `${url}/api/v1/sideData/viewSideData`,
  editsidedata: `${url}/api/v1/sideData/editSideData`,

  //linkmanagment
  contactdata: `${url}/api/v1/admin/contactUs`,
  viewlinkpage: `${url}/api/v1/link/viewLinkPage`,
  linkupdate: `${url}/api/v1/link/linkDataUpdate`,

  feautueduser: `${url}/api/v1/admin/featureUserList`,
  listuser: `${url}/api/v1/admin/listUser`,
  category: `${url}/api/v1/admin/listCategory`,
  addcategory: `${url}/api/v1/admin/addCategory`,
  viewcategory: `${url}/api/v1/admin/viewCategory`,
  editcategory: `${url}/api/v1/admin/editCategory`,
  editreachus: `${url}/api/v1/admin/editContactUs`,
  feauturecollectionlist: `${url}/api/v1/admin/featureCollectionList`,
  feautureunfeauturecollection: `${url}/api/v1/admin/feautureUnfeautureCollection`,
  feautureunfeautureuser: `${url}/api/v1/admin/featureUnfeatureUser`,
  viewfeauturecollection: `${url}/api/v1/admin/viewFeatureCollection`,
  viewfeautureuser: `${url}/api/v1/admin/viewFeatureUser`,
  deletecategory: `${url}/api/v1/admin/deleteCategory`,

  ///api/v1/nft/placeOrderListById/{_id}
  ///api/v1/admin/placeOrderList
  ///api/v1/admin/blockUnblockUser
  ///api/v1/admin/placeOrderList
  ///api/v1/admin/listNFT
  // https://node-agency-dirk.mobiloitte.com/api/v1/admin/placeOrderList
  ///api/v1/admin/viewNft
  ///api/v1/admin/cancelAlreadyPlaceOrder
  ///api/v1/admin/cancelAlreadyPlaceOrderList
  ///api/v1/admin/editProfile
  // /api/v1/admin/transactionList
  // /api/v1/admin/viewTransaction
  // /api/v1/admin/viewnftHistoryTransaction
  // /api/v1/admin/nftTransactionList
  // /api/v1/admin/
  // /api/v1/admin/viewNftTransaction/{nftId}
  // /api/v1/admin/cancelAlreadyPlaceOrder

  blockUnblockUser: `${url}/api/v1/admin/blockUnblockUser`,
  placeOrderList: `${url}/api/v1/admin/placeOrderList`,
  listNFT: `${url}/api/v1/admin/listNFT`,
  viewNft: `${url}/api/v1/admin/viewNftThroughUser`,
  viewNftList: `${url}/api/v1/admin/viewNft`,

  placeOrderListById: `${url}/api/v1/nft/placeOrderListById/`,
  cancelAlreadyPlaceOrder: `${url}/api/v1/admin/cancelAlreadyPlaceOrder`,
  cancelAlreadyPlaceOrderList: `${url}/api/v1/admin/cancelAlreadyPlaceOrderList`,
  transactionList: `${url}/api/v1/admin/transactionList`,
  // listuser: `${url1}/api/v1/admin/listUser`,
  // viewTransaction: `${url}/api/v1/admin/viewTransaction`,
  // /api/v1/admin/placeOrderList
  viewnftHistoryTransaction: `${url}/api/v1/admin/viewnftHistoryTransaction`,
  nftTransactionList: `${url}/api/v1/admin/nftTransactionList`,
  viewNftTransaction: `${url}/api/v1/admin/viewNftTransaction/`,
  dashboard: `${url}/api/v1/admin/dashboard/`,

  placeOrderList: `${url}/api/v1/admin/placeOrderList/`,
  viewCancelOrder: `${url}/api/v1/admin/viewCancelOrder`,
  forgotPassword: `${url}/api/v1/admin/forgotPassword`,
  resetPassword: `${url}/api/v1/admin/resetPassword`,
  reportsList: `${url}/api/v1/admin/reportsList`,
  blockUnblockReport: `${url}/api/v1/admin/blockUnblockReport/`,
  // staticPageList
  staticPageList: `${url}/api/v1/static/staticPageList`,
  viewStaticPage: `${url}/api/v1/static/viewStaticPage`,
  editStaticPage: `${url}/api/v1/static/editStaticPage`,




};

export default Apiconfig;
