import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Paper,
  Container,
  TextField,
  FormControl,
  Button,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Checkbox,
  Radio,
} from "@material-ui/core";
import { Link, URL } from "react-router-dom";
import Logo from "src/component/Logo";

const useStyles = makeStyles((theme) => ({
  content: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(images/nft-non-fungible-token_istock.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.content}>
      <Grid
        item
        xs={false}
        sm={5}
        md={6}
        lg={6}
        className={classes.image}
      ></Grid>
      <Grid item xs={12} sm={7} md={6} lg={6}>
        {children}
      </Grid>
    </Grid>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
