import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AuthContext } from "src/context/Auth";
import Apiconfig from "../../../config/APIConfig";
import axios from "axios";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { ACTIVE_NETWORK, NetworkContextName } from "src/constants";
import { useWeb3React } from "@web3-react/core";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  DialogTitle,
  Dialog,
  DialogContentText,
  DialogContent,
  IconButton,
  Badge,
  Avatar,
  Typography,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SwipeableTemporaryDrawer from "./RightDrawer";
import { Link, useHistory } from "react-router-dom";
import Logo from "src/component/Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.paper,
  },
  toolbar: {
    height: 80,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  avatar: {
    width: 50,
    height: 50,
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      {/* <Box>
        <img src="/images/logo1.png" style={{ width: "100%" }} alt="" />
        <Typography>hjghj</Typography>
      </Box> */}
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const history = useHistory();
  const { account, library, chainId } = useWeb3React();
  const [userdata, setuserdata] = React.useState([]);

  const accessToken = window.localStorage.getItem("creatturAccessToken");

  const profileapi = async () => {
    await axios
      .get(Apiconfig.profile, {
        headers: {
          token: `${accessToken}`,
        },
      })
      .then(async (res) => {
        if (res.data.response_code === 200) {
          setuserdata(res.data.result);
          console.log("hjghj)))))0000", res.data.result);
        } else if (res.data.response_code === 404) {
          console.log("/404");
        } else {
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    profileapi();
  }, []);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        p={2}
        style={{
          backgroundColor: "#252d47",
          width: "50px",
          height: "50px",
          padding: "10px",
          borderRadius: "50px",
        }}
      >
        <img src="/images/logo1.png" style={{ width: "100%" }} alt="" />
      </Box>
      <Logo
        width="180"
        onClick={() => history.push("/")}
        style={{ cursor: "pointer", paddingLeft: 16 }}
      />

      <Box flexGrow={1} />
      {/* <IconButton
        style={{ marginRight: 10 }}
        onClick={() => history.push("/notifications")}
      >
        <Badge badgeContent={auth.NotificationCount} color="secondary">
          <NotificationsIcon style={{ color: "#000" }} />
        </Badge>
      </IconButton> */}
      {/* <SwipeableTemporaryDrawer /> */}
      {/* <Avatar
        onClick={handleClick}
        src={
          userdata.profilePic !== null
            ? userdata.profilePic
            : "/images/user.png"
        }
        className={classes.avatar}
      /> */}
      {/* <ArrowDropDownIcon onClick={handleClick} />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link
          style={{ textDecoration: "none", color: "black" }}
          to="/adminprofile"
        >
          {" "}
          <MenuItem>Profile</MenuItem>
        </Link>
        <Link to="/login" style={{ textDecoration: "none", color: "black" }}>
          <MenuItem onClick={handleClose}>Logout</MenuItem>
        </Link>
      </Menu> */}
      <Typography style={{ marginLeft: "5px", color: "#fff" }}>
        {userdata.name}
      </Typography>

      <Dialog
        open={account && chainId !== ACTIVE_NETWORK}
        minWidth="md"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Netwok Change Error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box ml={3} pb={3}>
              <Typography>
                Please switch to KOVAN {NetworkContextName}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
